/* Apply a reset to remove default margin and padding */
body, h1, h2, form, div {
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

.container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

h2 {
    text-align: left;
    margin-bottom: 20px;
}

#ContactForm {
    width: 50%;
    alignment: left;
    display: grid;
    gap: 10px;
}

#ContactForm label {
    font-weight: bold;
}

#ContactForm input, #ContactForm textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    width: 100%;
}

#ContactForm textarea {
    height: 150px;
    resize: none;
}

#ContactForm button {
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    width: 20%;
}

#ContactForm button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

#ContactForm button:hover {
    background-color: #0056b3;
}
@media screen and (max-width: 800px) {
    #ContactForm {
        width:90%;
    }
    #ContactForm button {

    width: 30%;
}



}
