.header {
  display: grid;
  grid-template-columns: 1fr auto; /* Create two columns, one for the content and one for the span */
  background-color: #3498db;
  color: white;
  text-align: center;
  padding: 20px;
  align-items: center; /* Vertically center items within the grid container */
}

.header h1 {
  margin: 0;
  padding: 0;
  grid-column: 1; /* Place h1 in the first column */
}

.header span {
  justify-self: end; /* Align the span to the end of its column (top-right corner) */
  padding: 5px 10px; /* Optional padding for styling */
}

.header .hlink {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

/* Media Query for Portrait Mode */

@media screen and (max-width: 600px) {
  .header {
    grid-template-columns: 1fr; /* Display one column */
  }
  .header span {
    justify-self: center; /* Align the span to the start of its column (top-left corner) */
  }
}