/* Center the content vertically and horizontally */


/* Style the form container */
.login-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    margin-top: 40px;
    min-height:300px;
    max-width:500px;

    width: 40vw;
    height:40vh;
}


/* Style the heading */
.login-form-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

/* Style the tab buttons */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-button {
    background-color: transparent;
    border: none;
    color: #555;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    margin: 0 10px;
}

.tab-button.active {
    color: #3498db;
    border-bottom: 2px solid #3498db; /* Add an underline for the active tab */
}

/* Style the input fields */
.input-field {
    border: 1px solid #ccc;
    border-radius: 5px; /* Rounded corners for input boxes */
    font-size: 16px;
    margin-bottom: 4vh;
    padding: 10px;
    width: 95%;
}

/* Style the login/sign-up button */
.submit-button {
    background-color: #3498db;
    border: none;
    border-radius: 5px; /* Rounded corners for the button */
    color: white;
    cursor: pointer;
    font-size: 16px;
    padding: 10px ;
    width: 98%;
}

.submit-button:hover {
    background-color: #0076bd; /* Darker color on hover */
}


@media screen and (max-width: 800px) {
    .login-form-container {
        width: 80vw;
    }


}

@media screen and (max-width: 1000px) {
    .login-form-container {
        width: 60vw;
    }
}