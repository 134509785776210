/* HomePage.css */

.homepage {
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
}

.homepage h2 {
    font-family: Arial, sans-serif;
}

.header h1 {
    margin: 0;
}

.main {
    padding: 10px;
}

.main section {
    margin-bottom: 10px;
}

/* Add more styles as needed */
