#submit {
  background-color: #007bff; /* Background color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Padding around text */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  font-size: 16px; /* Font size */
  text-align: center; /* Center text horizontally */
  text-decoration: none; /* Remove underlines from links (optional) */
  display: inline-block;
  margin: 10px 0; /* Add margin to create space between buttons (optional) */
  transition: background-color 0.3s ease; /* Smooth background color transition on hover */

  &:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
}

/* You can add more styles or modify the existing ones to suit your design. */
