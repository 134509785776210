/* MyAccount.css */

/* Container styles */
.container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 20px; /* Gap between grid items */
}

/* User info styles */
.user-info {
    grid-column: span 2;

}
.acc-button {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    display: inline-block;
    flex-direction: column;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;

}

.acc-button:hover {
    background-color: #ccc;
    cursor: pointer;
}
/* Change dialogs styles */
.change-dialog {
    display: block;
    gap: 10px; /* Gap between grid items */
}

/* Payment methods styles */
.payment-methods {
    margin-top: 20px; /* Adjust as needed */
}

/* Add your additional styles here */
