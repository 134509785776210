/* Center the Stripe element container and add a shadow */
.stripe-container {
    display: flex;
    flex-direction: column; /* Place children vertically */
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
}

/* Style for the h1 heading */
.stripe-container h1 {
    text-align: center;
    margin-bottom: 20px; /* Add spacing between title and form */
}

/* Style for the form container */
.form-container {
    width: 100%; /* Ensure the form takes full width */
    max-width: 400px; /* Adjust as needed */
}
